// Set the colors to blue and amber
$primary-color: #709abb; // blue-500
$accent-color: #FFD740; // amber-A200
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";

@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';


/* You can add global styles to this file, and also import other style files */
* {
  margin:0;
  padding:0;
  box-sizing:border-box;
}
::selection {
  color: #fff;
  background: #1665D8;
}
@font-face {
  src: url(./assets/font/CircularStd-Black.ttf);
  font-family: CircularStd;
}
@font-face {
  src: url(./assets/font/CircularStd-Bold.ttf);
  font-family: CircularStd;
  font-weight: 700;
}
@font-face {
  src: url(./assets/font/CircularStd-Medium.ttf);
  font-family: CircularStd;
  font-weight: 400;
}

/* General */

body {
  font-family: CircularStd;
}
.body-overflow{
  overflow-y: hidden !important ;
}
body::-webkit-scrollbar {
  display: none;
  
}
.btns {
  background: #1665D8;
  border-radius: 500px;
  color: #fff !important;
  font-weight: 400;
}
.btn:focus{
  box-shadow: none;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 111111;
}
.modal-open .modal::-webkit-scrollbar {
  display: none;
}

.owl-prev {
  position: absolute !important;
  right: 15% !important;
  top: -31% !important;
  cursor: pointer !important;
  background: transparent !important;
}
.owl-next {
  position: absolute !important;
  right: 10% !important;
  top:-31% !important;
  cursor: pointer !important;
  background: transparent !important;
}
@media (max-width: 1682px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1240px;
  }
}
@media (min-width: 1440px) {
  .owl-prev {
    right: 17.5% !important;
    top: -31% !important;
  }
  .owl-next {
    right: 11.7% !important;
    top:-31% !important;
  }
}


/* Navbar starts here  */

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 20px 40px;
}
.navbar .navbar-brand {
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  text-transform: uppercase;
  color: #1D293F;
}
.navbar .navbar-brand img{
  height: 46px;
  width: 226px;
}

.navbar .navbar-nav .nav-item .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #1D293F;
}
.navbar .navbar-nav .nav-item .nav-link.btns {
  padding:10px 22px;
}

/* navbar ends  */
select {
  background-image: url(/../assets/images/down.svg);
  background-position: right 9px center;
    background-repeat: no-repeat;
    background-size: auto 65%;
  border-radius:2px;
  border:none;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &::-ms-expand { display: none };
}
.align{
  .select2-selection {
    border: 1px solid rgba(72, 81, 96, 0.32) !important;
    border-radius: 50px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #475060 !important;
    outline: none !important;
    margin-top: 0 !important;
  }
  .select2-selection .select2-selection__rendered {
    line-height: 16px !important;
    padding-left: 22px !important;
    color: #475060!important;
    font-size: 12px !important;
  }
  .select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 6px 5px !important;
  }
}
.ag-layout-normal{
  font-family: DM Sans;
}
.ag-cell-value{
  font-family: DM Sans !important;
}
.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  content: "\f106";
  color: var(--ag-checkbox-checked-color, var(--ag-material-accent-color, #1665D8)) !important;
  color: #1665D8 !important;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.ag-root.ag-layout-normal::-webkit-scrollbar {
  display: none !important;
}
.ag-theme-material {
  height: 80vh !important;
  float: left !important;
  width: 100% !important;
}

// payemnt card
//del model class is used in a model of profile and filter delete model 
  .delmodal{
        .modal-content{
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

            .modal-header{
                margin-left: 20px;
                h4{
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 23px;
                    color: #1D293F;
                    text-align: left;
                }
            }
            .modal-footer{
                margin-left:20px;
                padding-bottom: 45px;
                .btn{
                    font-family: CircularStd;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #fff;
                    background-color:#1665D8 ;
                    padding: 12px 30px;
                    border-radius: 64px;
                }
                .delBtn{
                    background-color: gray;
                }
            }
        }
    }

.addPayment{
  background: #FFFFFF !important;
  border: 1px solid rgba(72, 81, 96, 0.24) !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 62px !important;
  padding: 22px 20px !important;
  margin-top: 15px !important;
}

// badge used in search history btns

.badge.badgeHistory{
  color: #FFFFFF;
  background: #1665D8;
  border: none;
  font-size: 15px;
  font-weight: 400;
  margin-right: 5px;
}

/* navgators starts here */

#wrapper .map-navigator {
  position: absolute;
  top: 24%;
  right: 3%;
}
#wrapper .map-navigator .zoom-controls {
  width: 40px;
  height: 96px;
  background: #FFFFFF;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1), 0px 0px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  text-align: center;
  padding: 10px;
}
#wrapper .map-navigator .zoom-controls hr{
  border: 1px solid #DEE2E6;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
#wrapper .map-navigator .zoom-controls img{
  width: 16px;
  height: 16px;
}

#wrapper .map-navigator .edit {
  width: 40px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1), 0px 0px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  margin-top: 14px;
  padding: 12.5px;
}
#wrapper .map-navigator .edit img{
  width: 16px;
  height: 16px;
}


/* page wrapper content starts for dual view */

// #wrapper #page-content-wrapper {
//   width: 100%;
//   min-height: 76vh;
//   overflow-y: auto;
//   margin-left: auto;
//   position: absolute;
//   left: 0;
//   background: none;
//   border: 1px solid rgba(72, 81, 96, 0.24);
//   box-sizing: border-box;
// }
#wrapper #page-content-wrapper::-webkit-scrollbar {
  display: none;
}

// selected state of a button
.tab-selected {
  background: #dbeafe !important;
  border: 1px solid #6983ce !important;
}

/* Dashboard ends here */

@media (max-width:1025px) {
  .ag-theme-material {
    height: 1072px !important;
    float: left !important;
    width: 100% !important;
  }
}
@media (max-width:800px) {
  .owl-prev {
      position: absolute !important;
      right: 24% !important;
      top: -18.5% !important;
      cursor: pointer !important;
  }
  .owl-next {
      position: absolute !important;
      right: 4% !important;
      top:-18.5% !important;
      cursor: pointer !important;
  }
  .ag-theme-material {
    height: 705px !important;
    float: left !important;
    width: 100% !important;
  }
}

// Ag-grid customization
.ag-menu.ag-ltr.ag-popup-child {
  font-family: "DM SANS";
  border-radius: 10px;
}

.ag-theme-material .ag-icon-menu::before {
  content: '\f114'!important;
}

.ag-theme-material .ag-row-selected {
  background-color: rgba(117, 125, 138, 0.06)!important;
  background-color: var(--ag-selected-row-background-color,rgba(117, 125, 138, 0.06))!important;
}

.leaflet-bar {
  padding: 15px 5px !important;
    background-color: #ffffff !important;
    border: 2px solid rgba(0,0,0,0.2) !important;
    background-clip: padding-box !important;
    outline: none !important;
    border: none !important;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.1), 0px 0px 10px -5px rgba(0, 0, 0, 0.04) !important;
    border-radius: 40px !important;
}

.leaflet-bar a:first-child{
  padding: 10px 10px 24px 10px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #a2acac !important;
  &:hover{
    background-color: transparent;
  }
}

.leaflet-bar a:last-child{
  position: relative;
  display: flex !important;
  justify-content: center !important;
  padding:  6px 15px 25px 15px !important;
  color: #a2acac !important;
  &:hover{
    background-color: transparent;
  }
}